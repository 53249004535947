import * as styles from '../../styles/scss/components/Card/Card.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { setOfflineModalState, buzzOfflineModal } from '../../store/modals/modalsActions';
import api from '../../axios/api';
import gsap from 'gsap';
import { NEXT_PUBLIC_SERVER_ENVIRONMENT } from '../../config/env.config';
import useTranslation from 'next-translate/useTranslation';
import { deviceType } from '../../helpers/functions.js'
import { cdnLoader } from '../../config/cdnImageLoader.config';
import { throttle } from 'lodash';

const Card = (props) => {
  const {
    playerIndex,
    card,
    isVictim,
    gamePublicActionChannel,
    gameId,
    lobbyId,
    roundState,
    isStealingMode,
    onGameCommontCardEvent,
    socketId,
    actionCard,
    cardPlayAction,
    myTurn,
    id,
    dropped,
    playingCard,
    stealingCard,
    playingMarble,
    chosenAsVictim,
    stealerId,
    responsive,
    device,
    victimPlayerChosenCardId,
    deviceForSetal,
    forceFinished,
    stealingInProgress,
    setStealingInProgress,
    onSetChosenVictim,
  } = props

  const [chosenCardForStealing, setChosenCardForStealing] = useState(null);
  const { cardStyle } = useSelector(state => state.settings)

  const victimCardRef = useRef(null);

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleOpenOfflineModalState = (state) => {
    dispatch(setOfflineModalState(state))
  }
  const buzzModalHandler = (state) => {
    dispatch(buzzOfflineModal(state))
  }

  useEffect(() => {
    const ctx = gsap.context(() => {
      const target = victimCardRef.current || ".victimPlayer .victimCard > div";
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);

      gsap.to(target, {
        y: isVictim && chosenAsVictim ? (isMobile ? 210 : 200) : 0,
        scale: isVictim && chosenAsVictim ? (isMobile ? 1.8 : 1.5) : 1,
        duration: 0.3,
      });
    });

    return () => ctx.revert(); // Automatically clean up
  }, [isVictim, chosenAsVictim, isStealingMode]);

  //just for testing
  const [showMenu, setShowMenu] = useState(false)

  const handleContextOpen = (e) => {
    if (NEXT_PUBLIC_SERVER_ENVIRONMENT !== 'production') {
      e.preventDefault();
      setShowMenu(prevstate => !prevstate)
    }
  }

  const throttledChoosingCardForStealing = useCallback(
    throttle((cardId, stealerId, victimId) => {
      if (deviceForSetal === 'mobile') return;

      if (isStealingMode && isVictim && myTurn && chosenAsVictim) {
        gamePublicActionChannel.trigger('client-choosingForSteal', {
          cardId,
          stealer: stealerId,
          victim: victimId,
        });
      }
    }, 100),
    [deviceForSetal, isStealingMode, isVictim, myTurn, chosenAsVictim, gamePublicActionChannel]
  );

  const choosingCardForStealing = useCallback((cardId, stealerId, victimId) => {
    throttledChoosingCardForStealing(cardId, stealerId, victimId);
  }, [throttledChoosingCardForStealing]);

  useEffect(() => {
    return () => {
      throttledChoosingCardForStealing.cancel();
    };
  }, [throttledChoosingCardForStealing]);

  useEffect(() => {
    if (!!gamePublicActionChannel && !isStealingMode) {
      setChosenCardForStealing(null);
      gamePublicActionChannel.unbind(`client-choosingForSteal`);
    } else if (!!gamePublicActionChannel && isStealingMode) {
      gamePublicActionChannel.bind('client-choosingForSteal', (data) => {
        if (data.cardId === card.id) {
          setChosenCardForStealing(data.cardId)
        } else {
          setChosenCardForStealing(null)
        }
      })
    }
  }, [isStealingMode, gamePublicActionChannel])

  const getCardClassName = (card, chosenCardForStealing, playerIndex, myTurn, id, dropped, roundState, actionCard) => {
    let className = styles.card
    if (card.boardCard || card.stealModalCard) {
      return className
    }
    if ((chosenCardForStealing === card.id) || (victimPlayerChosenCardId === card.id && deviceForSetal === 'mobile')) {
      className = className + ' ' + styles.highlighted + ' ' + styles[`highlighted_player_${playerIndex + 1}`]
    }

    if (
      (myTurn && card.playerId === id && !card.canPlay && roundState === 'play') ||
      (card.playerId === id && dropped)
    ) {
      className = className + ' ' + styles.unplayable
    }

    if (
      ((myTurn && roundState === 'play') || roundState === 'exchange') &&
      card.playerId === id &&
      card.canPlay
    ) {
      className = className + ' ' + styles.playAble
    }

    if (roundState === 'exchange' && card.playerId === id) {
      className = className + ' ' + styles.cardInExchangeState
    }

    if (card.id === actionCard.id && card.playerId === id && roundState === 'exchange') {
      className = className + ' ' + styles.readyForExchange
    }

    if (card.playerId === id) {
      className = className + ' ' + styles.cardAlias
    }

    if (!myTurn && playerIndex === 0) {
      className = className + ' ' + styles.notMyTurn
    }

    return className
  }


  const hanldeCardClick = async (event, card) => {

    if (isStealingMode && isVictim && !chosenAsVictim) {
      const target = victimCardRef.current;
      if (target) {
        gsap.to(target, {
          y: 0,
          scale: 1,
          duration: 0.3,
          onComplete: async () => {
            if (id === stealerId) {
              gamePublicActionChannel.trigger("client-choosingVictim", {
                stealerId: stealerId,
                victimId: card.playerId,
              });

              const requestData = {
                gameId: gameId,
                lobbyId: lobbyId,
                socketId: socketId,
                payload: {
                  type: "chooseVictim",
                  victimId: card.playerId,
                  cardId: actionCard.id,
                },
                actionCard: {
                  ...actionCard,
                },
              };

              await onGameCommontCardEvent(requestData);
              onSetChosenVictim(card.playerId);
            }
          },
        });
      }
      return;
    }
    //end of code
    if (
      forceFinished) {
      console.log("isStealingMode && deviceForSetal === 'mobile') || forceFinished")
      return
    }
    if (chosenAsVictim) {
      event.stopPropagation();
    }
    if (card.stealModalCard) {
      return
    }
    if (card.boardCard) {
      openBoardCardsModule()
      return
    }
    if (!socketId && !card.modalCard) {
      // console.log("!socketId && !card.modalCard")
      handleOpenOfflineModalState(true)
      buzzModalHandler(true)
      return
    }
    const requestData = {
      gameId: gameId,
      lobbyId: lobbyId,
      socketId: socketId,
      actionCard: {
        ...card,
        transform: actionCard.transform
      }
    }
    if (roundState === 'exchange' && card.playerId === id) {
      requestData.payload = {
        type: "exchange",
        cardId: card.id
      }

      setTimeout(() => {
        onGameCommontCardEvent(requestData)
      }, 100)
      return
    } else if (roundState === 'play' && myTurn) {
      if (!isStealingMode && card.canPlay && (!playingCard || card.jokerModalCard) && !playingMarble) {
        if (cardPlayAction === 'playAs') {
          requestData.payload = {
            type: 'playCard',
            cardId: card.id,
            playAs: {
              ...card
            },
            transform: actionCard.transform
          }
          onGameCommontCardEvent(requestData);
          return
        } else if (card.playerId === id && cardPlayAction === '') {
          const randomCard = Math.floor(Math.random() * 4);
          const cardRotation = [-11.74, 6.74, 6.68, 0];

          requestData.payload = {
            type: 'playCard',
            cardId: card.id,
            playAs: card.playAs,
            transform: cardRotation[randomCard]
          }
          const cardElement = document.getElementById(`_${card.id}`);
          if (cardElement) {
            cardElement.parentElement.classList.add('played');
          }
          onGameCommontCardEvent(requestData);

        } else {

          return
        }
      } else {
        if (stealingCard || stealingInProgress || (!isStealingMode && id !== card.playerId) || (playingCard && id === card.playerId)) {
          return
        }
        setStealingInProgress && setStealingInProgress(true)
        if (isVictim && chosenAsVictim) {

          requestData.payload = {
            type: 'steal',
            cardId: card.id,
            twoId: actionCard.id
          }
          gsap.to(".victimPlayer .playerCard > div", {
            y: 0,
            scale: 1,
            duration: .3,
            stagger: 0.1,
            onComplete: () => {
              onGameCommontCardEvent(requestData)
            }
          });

          return
        } else {

        }
      }
    }
  }

  const getCardSrc = (cardCode, CardType, visible, isAlias, stealModalCard) => {
    // console.log(isAlias)
    if (cardStyle.id === 'easy') {
      let cardCodeInner = ''
      if (visible) {
        cardCodeInner = cardCode
        if (stealModalCard) {
          cardCodeInner = card.codeAlias || card.code
        } else if (isAlias) {
          cardCodeInner = 'JK' + card.codeAlias
        }
      } else {
        cardCodeInner = 'back'
      }
      if (deviceType() == 'mobile' || deviceType() == 'tablet') {
        return `/images/cards/easy-compressed/${cardCodeInner}.svg`
      }
      return `/images/cards/easy/${cardCodeInner}.svg`
    }
    let cardName = ''
    if (!visible) {
      cardName = 'back'
    } else if (stealModalCard) {
      cardName = card.codeAlias ? card.codeAlias + card.typeAlias : card.code + card.type
    } else if (isAlias) {
      cardName = 'JK' + card.codeAlias + card.typeAlias
    } else if (cardCode !== 'JK') {
      cardName = cardCode + CardType
    } else {
      cardName = cardCode
    }


    return `/images/cards/classic/${cardName}.svg`
  }

  return (
    <>

      {showMenu && <ContextMenu
        lobbyId={lobbyId}
        gameId={gameId}
        showMenu={showMenu}
        card={card}
        setShowMenu={() => { setShowMenu(false) }}
        gamePublicActionChannel={gamePublicActionChannel}
        userId={id}
      />
      }

      {card.modalCard || responsive ? (
        <div
          ref={victimCardRef}
          className={`${getCardClassName(card, chosenCardForStealing, playerIndex, myTurn, id, dropped, roundState, actionCard)} ${(id === stealerId && chosenAsVictim && isStealingMode && deviceForSetal !== 'mobile') ? `${styles.showHover} ${styles[`highlighted_player_${playerIndex + 1}`]}` : ''}`}
          onClick={(e) => hanldeCardClick(e, card)}
          onMouseOver={(e) => { choosingCardForStealing(card.id) }}
          onMouseOut={(e) => { choosingCardForStealing(null) }}
          datacardid={card.id}
          id={`_${card.id}`}
          onContextMenu={handleContextOpen}
          device={device}
        >

          <img
            src={`${cdnLoader(getCardSrc(card.code, card.type, card.visible, card.isAlias, card.stealModalCard))}`}
            alt="image of the card"
          />
          <div className={styles.exchange_overlay}>
            <button>{t('common:choose')}</button>
          </div>
          {card.exchanged && !card.modalCard && (
            <div className={`${styles.from_other_player} ${styles[card.takenFrom.color]}`}>
              <img src={cdnLoader('/images/gameModal/exchange.png')} />
            </div>
          )}
          {card.stolen && !card.modalCard && (
            <div className={`${styles.from_other_player} ${styles[card.takenFrom.color]}`}>
              <img src={cdnLoader('/images/gameModal/steal.png')} />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const areEqual = (prevProps, nextProps) => {
  if (prevProps.isStealingMode !== nextProps.isStealingMode || prevProps.stealingInProgress !== nextProps.stealingInProgress || prevProps.playingMarble !== prevProps.playingMarble) {
    return false
  }
  if (prevProps.stealingCard !== nextProps.stealingCard && nextProps.chosenAsVictim) {
    // console.log('re rendering', prevProps.id)
    return false
  }
  if (nextProps.roundState === 'exchange' && nextProps.actionCard.id !== nextProps.card.id && prevProps.actionCard.id !== nextProps.card.id) {
    return true
  }
  if (nextProps.isStealingMode && !nextProps.isVictim) {
    return true
  }
  else {
    return false
  }
}

export default React.memo(Card, areEqual)
// export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Card, areEqual))

//just for testing
const ContextMenu = (props) => {

  const {
    card,
    setShowMenu,
    lobbyId,
    gameId,
    gamePublicActionChannel,
    userId
  } = props

  const changeCard = (e) => {
    // console.log(e.target.innerHTML)
    gamePublicActionChannel.trigger('client-someoneChangedCard', {
      userId: userId
    });
    api.post(`/lobby/${lobbyId}/game/${gameId}/change-card`, {
      cardId: card.id,
      cardCode: e.target.innerHTML
    })
      .then(response => {
        // console.log(response)
        window.location.reload();
      }).catch(e => {
        // console.log(e)
      })
    setShowMenu()
  }
  return (
    <g width="100" height="100">
      <text x='0' y='-50' onClick={changeCard}>A</text>
      <text x='20' y='-50' onClick={changeCard}>2</text>
      <text x='40' y='-50' onClick={changeCard}>3</text>
      <text x='0' y='-35' onClick={changeCard}>4</text>
      <text x='20' y='-35' onClick={changeCard}>5</text>
      <text x='40' y='-35' onClick={changeCard}>6</text>
      <text x='0' y='-20' onClick={changeCard}>7</text>
      <text x='20' y='-20' onClick={changeCard}>8</text>
      <text x='40' y='-20' onClick={changeCard}>9</text>
      <text x='60' y='-20' onClick={changeCard}>10</text>
      <text x='0' y='-5' onClick={changeCard}>J</text>
      <text x='20' y='-5' onClick={changeCard}>Q</text>
      <text x='40' y='-5' onClick={changeCard}>K</text>
    </g>
  )
}