import React from 'react';
import { HexButtons, RedHexButtons } from "../../components/Buttons/Buttons";
import ChevronDown from '../../public/images/gamePageButtons/chevron_down.svg';
import Info from '../../public/images/common/info-svg.svg';
import HomeSvg from '../../public/images/common/home.svg';
import NotificationSvg from '../../public/images/common/notification-svg.svg';
import NotificationsModal from "../../components/Notifications/NotificationsModal/NotificationsModal";
import Hamburger from '../../public/images/common/hamburger-svg.svg';
import Fullscreen from '../../public/images/gamePageButtons/fullscreen.svg';
import * as styles from '../../styles/scss/components/Header/GamepageHeader.module.scss';
import gsap from 'gsap';

function GamepageHeader({
    fullScreen,
    toggleInfo,
    adminHandler,
    notifRef,
    isIos,
    handleNotificationModalOpen,
    isNotifOpen,
    openNotif,
    menuRef,
    handleHamburgerMenuOpen,
    isMobile,
    isMenuOpen,
    children,
}) {
    const handleHeaderDropdown = () => {
        gsap.to('#header_dropdown_icon', {
            opacity: 0,
            onComplete: () => {
                gsap.to('#header_dropdown_wrapper', {
                    y: 60,
                    onComplete: () => {
                        gsap.to('#header_dropdown_wrapper', {
                            y: 0,
                            delay: 5,
                            onComplete: () => {
                                gsap.to('#header_dropdown_icon', { opacity: 1 });
                            },
                        });
                    },
                });
            },
        });
    };

    const impersonateToken =
        typeof window !== 'undefined' && localStorage.getItem('impersonate_token');

    return (
        <div
            className={`${styles.gamePage_ui_holder_header} ${
                isMobile ? styles.mobile : styles.desktop
            }`}
        >
            <div id="header_dropdown_wrapper" className={styles.header_dropdown_wrapper}>
                <div
                    id="header_dropdown_icon"
                    className={styles.header_dropdown_icon}
                    onClick={handleHeaderDropdown}
                >
                    <HexButtons>
                        <ChevronDown />
                    </HexButtons>
                </div>
                <div>
                    <div onClick={toggleInfo}>
                        <Info />
                    </div>
                </div>

                {!isIos && (
                    <div className={styles.header_fullscreen} onClick={fullScreen}>
                        <HexButtons>
                            <Fullscreen />
                        </HexButtons>
                    </div>
                )}

                <div className={styles.buttons_right}>
                    {impersonateToken && (
                        <div
                            className={`${styles.notifications} ${styles.button_container}`}
                            onClick={adminHandler}
                        >
                            <RedHexButtons>
                                <HomeSvg />
                            </RedHexButtons>
                        </div>
                    )}
                    <div
                        ref={notifRef}
                        className={`${styles.notifications} ${styles.button_container}`}
                    >
                        <div onClick={handleNotificationModalOpen}>
                            <NotificationSvg />
                        </div>
                        <div className={styles.notification_modal_container}>
                            {isNotifOpen && (
                                <NotificationsModal callBackFunction={openNotif} />
                            )}
                        </div>
                    </div>
                    <div
                        ref={menuRef}
                        className={`${styles.burger_menu} ${styles.button_container}`}
                    >
                        <div onClick={handleHamburgerMenuOpen}>
                            <Hamburger />
                        </div>
                        <div className={styles.navigation_hamburger}>{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const areEqual = (prevProps, nextProps) => {
    return (
        prevProps.isNotifOpen === nextProps.isNotifOpen &&
        prevProps.isMobile === nextProps.isMobile &&
        prevProps.isMenuOpen === nextProps.isMenuOpen &&
        prevProps.isIos === nextProps.isIos
    );
};

export default React.memo(GamepageHeader, areEqual);
